
  
  export default {
    name: 'LowerNavbarDrawer',
    components: {},
    props: {},
    data () {
      return {}
    }
  }
